import styled from 'styled-components';
import Wleft from './w-left.png';
import Rleft from './w-right.png';

export const Layout = styled.div`
  margin: auto;
  text-align: center;
  max-width: 300px;
  font-family: 'Roboto', sans-serif;
  img {
    max-width: 200px;
    margin: 50px 0 0 0;
  }
  p {
    font-size: 32px;
    font-weight: 600;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #bbbbbb;
  }
`;
export const SliderSpace = styled.div`
  height: 180px;
`;
export const Title = styled.div`
  p {
    margin: 15px 0;
  }
`;
export const LogoContainer = styled.div`
  overflow: hidden;
  border-radius: 23px;
  width: 120px;
  height: 120px;
  margin: auto;
  margin-top: 24px;
  img {
    margin: 0;
    max-width: 120px;
  }
`;
export const SliderContainer = styled.div`
  width: 400px;
  overflow: hidden;
  padding: 17px 10px 30px 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 28px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    height: 100%;
    width: 33px;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 28%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: 9;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-prev:before {
    display: none;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 37px;
    color: #000000;
    z-index: 9999;
  }
  .slick-prev,
  .slick-next {
    width: 35px;
    height: 35px;
  }
  .slick-dots {
    bottom: -35px;
  }
`;
export const SliderBg = styled.div`
  width: 300px;
  margin: auto;
`;
export const Button = styled.a`
  background: #0363f0;
  padding: 16px 0;
  margin-top: 60px;
  display: block;
  border-radius: 22px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
`;
export const Main = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin-bottom: 30px;
  :before {
    content: '';
    background-image: url(${Wleft});
    width: 70px;
    height: 153px;
    position: absolute;
    background-size: 60px;
    left: -15px;
    background-repeat: no-repeat;
    top: 0;
  }
  :after {
    content: '';
    background-image: url(${Rleft});
    width: 70px;
    height: 153px;
    position: absolute;
    background-size: 60px;
    right: -25px;
    background-repeat: no-repeat;
    top: 0;
  }
  p {
    margin-top: -5px;
    font-size: 25px;
    color: #9d9d9d;
  }
  h2 {
    font-size: 59px;
    margin: 0;
    div {
      display: inline-block;
    }
  }
`;
export const BoxContainer = styled.div`
  padding: 0 20px;
`;
export const Box = styled.div`
  box-shadow: 0px 0px 20px #b4b4b4;
  border-radius: 10px;
  padding: 15px;
  img {
    margin: 0;
  }
  p {
    font-size: 20px;
    text-align: left;
    margin: 0;
    &.lang-ar {
      text-align: right;
    }
  }
`;
export const BoxTitle = styled.div`
  text-align: left;
  font-size: 24px;
  padding: 12px 0;
  font-weight: 600;
  &.lang-ar {
    text-align: right;
  }
`;
