import React, { Component } from 'react';
import CleanLanding from '../../components/clean-landing';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../../components/seo';
import { Helmet } from 'react-helmet';

class LandingSEM extends Component {
  render() {
    const context = this.props.pageContext;
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <SEO title="Clean Up Storage" />
        <ResetCSS />
        <CleanLanding content={context} />
      </>
    );
  }
}

export default LandingSEM;
